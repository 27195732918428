import * as React from "react"
import Logo from "../Logo/Logo"
import { StoreContext } from "../../store"
import MainMenu from "../Menu/MainMenu"
import { graphql, StaticQuery } from "gatsby"

const LandingPageSignupOnlyCta = ({ headerLogo, viewPortWidth, superNavTopLevel }) => {
  const { setMobileSubState, isMobileMenuActive } = React.useContext(StoreContext)

  const handleClick = () => {
    if (viewPortWidth < 841) {
      setMobileSubState(false)
      if (typeof window !== "undefined" && window.document) {
        window.document.body.classList.remove("Pacnav--sub-is-active")
      }
    }
  }

  return (
    <StaticQuery
      query={graphql`
        {
          menu: wpMenu(name: { eq: "Landing Page w/CTA Primary" }) {
            menuItems {
              nodes {
                label
                url
                order
              }
            }
          }
        }
      `}
      render={data => {
        const menuItems = data?.menu?.menuItems?.nodes

        return (
          <div className="Wrap">
            <div className="Header-logo">
              <div className="Logo">
                <Logo logo={headerLogo} />
              </div>
            </div>


            <div className="Header-wrap">
              <MainMenu
                topLevel={menuItems}
                superMenu={superNavTopLevel}
                menuType="landing-page-signup-cta"
              />
            </div>

            <div className={`Header-cta signup-only-link`}>
              <a
                href={`/get-started/signup/`}
                className="Button is-default is-small"
              >
                Get Started
              </a>
            </div>
          </div>
        )
      }}
    />

  )
}

export default LandingPageSignupOnlyCta
