import { Link } from "gatsby"
import * as React from "react"
import * as styles from "./MobileMenu.module.css"
import MobileMenuItemList from "./MobileMenuItemList"

const MobileMenu = ({ topLevel, menuType = "" }) => {
  const hardcodedMenuItems = [
    {
      id: null,
      label: "Pricing",
      url: "/pricing/",
      cssClasses: [],
      childItems: null,
      description: null,
    },
    {
      id: null,
      label: "Contact",
      url: "/contact-us/",
      cssClasses: [],
      childItems: null,
      description: null,
    },
  ]
  return (
    <div className="PacnavMobile">
      {
        menuType === "landing-page-signup-cta" ? (
          <div className={styles.ButtonContainer}>
            <Link class={`Button is-default ${styles.Button}`} to="/get-started/signup/">
              Get Started
            </Link>
          </div>
        ) : (
          <>
            {/* "Get Started" Button */}
            <div className={styles.ButtonContainer}>
              <Link class={`Button is-default ${styles.Button}`} to="/get-started/">
                Get Started
              </Link>
            </div>

            <div className={styles.ButtonContainer}>
              <Link class={`Button is-outline ${styles.Button}`} to="/get-a-quote/">
                Get A Quote
              </Link>
            </div>
          </>
        )
      }

      {/* Main Nav Items */}
      <MobileMenuItemList
        menuItems={topLevel}
        childLevel={0}
      ></MobileMenuItemList>

      {/* Hardcoded Nav Items */}
      <MobileMenuItemList
        menuItems={hardcodedMenuItems}
        childLevel={0}
      ></MobileMenuItemList>

      {/* Log In */}
      <a
        className={`Pacnav--stick-bottom-mobile ${styles.LogInLink}`}
        href="https://app.goodhire.com/member/login"
      >
        LOG IN
      </a>
    </div>
  )
}

export default MobileMenu
